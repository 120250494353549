import React from "react";
// import {Button, Box} from "@mui/material";
import { withRouter } from "react-router-dom";
import axios from "axios";
import user from "../../Models/user";
import { useDispatch } from "react-redux";
import { LogoutAction } from "../../redux/actions/AuthActions";
import { useHistory } from "react-router-dom";

function LogoutButton() {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = (e) => {
    dispatch(LogoutAction(history));
    // e.preventDefault()

    // axios.post('http://localhost:8080/api/logout')
    //     .then(() => {
    //         //successful response
    //     })
    //     .catch(() => {
    //         //handle if something went wrong
    //     })
    //     .then(() => {
    //         //this code will be definitely executed
    //         user.logout(afterUserDestroyed)
    //     })
  };

  const afterUserDestroyed = () => {
    history.push("/login");
  };

  return (
    <>
      <button type="button" onClick={logout}>
        Logout
      </button>
    </>
  );
}

export default withRouter(LogoutButton);
