import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import Navbar from "../components/Navbars/AuthNavbar.js";
import FooterSmall from "../components/Footers/FooterSmall.js";

export const Auth = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const profileResponse = useSelector((state) => state.userDetails.userProfile);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Navbar transparent />
            <main>
              <section className="relative w-full h-full py-40 min-h-screen">
                <div
                  className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../assets/img/register_bg_2.png").default +
                      ")",
                  }}
                ></div>
                <Component {...props} {...rest} />
                <FooterSmall absolute />
              </section>
            </main>
          </>
        );
      }}
    />
  );
};
