import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "../../MainLayout/MainLayout";
import user from "../../Models/user";
// import {Grid, Typography} from "@mui/material";

function Dashboard() {
    const profileResponse = useSelector(state => state.userDetails.userProfile);
    return (
        <MainLayout title={"Dashboard"}>
            {/* {JSON.stringify(localStorage.getItem("profile_data"))} */}
            <br/>
            Hello {user.name}, you're logged in!
            <p><b>Local Storage :</b> {JSON.stringify(user)}</p>
            <br/>
            <p><b>State :</b> {JSON.stringify(profileResponse)}</p>
            
        </MainLayout>
    )
}

export default Dashboard
