import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import Dashboard2 from "./components/Dashboard/Dashboard2";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/Home";
import { wrappedComponents } from "./WrappedComponents";
import { useEffect, useState } from "react";
import { LoadProfileAction } from "./redux/actions/ProfileActions";
import { Auth } from "./layouts/Auth";
import Header from "./components/Headers/Header";
import Footer from "./components/Footers/Footer";
// import Header from "./components2/layout/header/header";

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const profileResponse = useSelector((state) => state.userDetails.userProfile);
  useEffect(() => {
    dispatch(LoadProfileAction());
    // setLoading(false)
    return () => {};
  }, [dispatch]);
  return (
    <BrowserRouter>
        <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <ProtectedRoute exact path={"/dashboard"} component={Dashboard} />
        <Auth exact path={"/login"} component={Login} />
      </Switch>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
