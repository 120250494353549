import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

const bannerData = [
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
];
const bannerData2 = [
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
];

const productData = [
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
  { url: "/assets/img/banner/1.png" },
];

const Home = () => {
  return (
    <>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
          <div className="embed-responsive aspect-ratio-30% rounded">
            <Swiper
              navigation={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination]}
              className="mySwiper embed-responsive-item"
            >
              {bannerData.map((item, i) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url("${item.url}")`,
                  }}
                  className="bg-cover bg-center"
                ></SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
          <div className="">
            <div className="content-heading">
              <div className="title-block">
                <h2>Flash Sale!</h2>
              </div>
              <div className="list-link">
                <ul>
                  <li className="active">80% off </li>
                  <li>
                    <a href="https://wpbingosite.com/wordpress/digic/product-category/computer-laptop/">
                      60% off
                    </a>
                  </li>
                  <li>
                    <a href="https://wpbingosite.com/wordpress/digic/shop/">
                      40% off
                    </a>
                  </li>
                  <li>
                    <a href="https://wpbingosite.com/wordpress/digic/product-category/digital-cameras/">
                      20% off
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <Swiper
                slidesPerView={4}
                navigation={true}
                loop={true}
                modules={[Navigation, Pagination]}
                className="mySwiper embed-responsive-item products-list grid"
              >
                {bannerData.map((item, i) => (
                  <SwiperSlide
                    className="item-product slick-slide slick-current slick-active"
                    style={{ width: 288 }}
                    tabIndex={0}
                    data-slick-index={0}
                    aria-hidden="false"
                  >
                    <div className="items">
                      <div className="products-entry content-product4 clearfix product-wapper">
                        <div className="products-thumb">
                          <div className="product-lable"></div>
                          <div className="product-thumb-hover">
                            <a
                              href="https://wpbingosite.com/wordpress/digic/shop/bluetooth-wireless-speaker/"
                              className="woocommerce-LoopProduct-link"
                              tabIndex={0}
                            >
                              <img
                                width={480}
                                height={480}
                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image entered lazyloaded"
                                
                                data-lazy-src="/assets/uploads/bluetooth-wireless-speaker-480x480.jpg.webp"
                                src="/assets/uploads/bluetooth-wireless-speaker-480x480.jpg.webp"
                                data-ll-status="loaded"
                              />
                              <img
                                width={480}
                                height={480}
                                className="hover-image back entered lazyloaded"
                                
                                data-lazy-src="/assets/uploads/bluetooth-wireless-speaker3-480x480.jpg.webp"
                                src="/assets/uploads/bluetooth-wireless-speaker3-480x480.jpg.webp"
                                data-ll-status="loaded"
                              />
                            </a>
                          </div>{" "}
                          <div className="product-button">
                            <div
                              className="woosw-wishlist"
                              data-title="Wishlist"
                            >
                              <button
                                className="woosw-btn woosw-btn-3986"
                                data-id={3986}
                                tabIndex={0}
                              >
                                Add to wishlist
                              </button>
                            </div>
                            <div
                              className="woosc-compare"
                              data-title="Compare"
                            >
                              <button
                                className="woosc-btn woosc-btn-3986"
                                data-id={3986}
                                tabIndex={0}
                              >
                                Compare
                              </button>
                            </div>
                            <span className="product-quickview">
                              <a
                                href="#"
                                data-title="Quick View"
                                data-product_id={3986}
                                className="quickview quickview-button quickview-3986"
                                tabIndex={0}
                              >
                                Quick View <i className="icon-visibility" />
                              </a>
                            </span>{" "}
                          </div>
                        </div>
                        <div className="products-content">
                          <div className="contents">
                            <div className="rating none">
                              <div className="star-rating none" />
                              <div className="review-count">(0)</div>
                            </div>
                            <div className="cat-products">
                              <a
                                href="https://wpbingosite.com/wordpress/digic/product-category/speakers/"
                                tabIndex={0}
                              >
                                Speakers
                              </a>
                            </div>{" "}
                            <h3 className="product-title">
                              <a
                                href="https://wpbingosite.com/wordpress/digic/shop/bluetooth-wireless-speaker/"
                                tabIndex={0}
                              >
                                Bluetooth Wireless Speaker
                              </a>
                            </h3>
                            <span className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    $
                                  </span>
                                  115.00
                                </bdi>
                              </span>
                            </span>
                            <div className="btn-atc">
                              <div data-title="Add to cart">
                                <a
                                  rel="nofollow"
                                  href="?add-to-cart=3986"
                                  data-quantity={1}
                                  data-product_id={3986}
                                  data-product_sku
                                  className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  tabIndex={0}
                                >
                                  Add to cart
                                </a>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
          {bannerData2.map((item, i) => (
            <div className="w-1/3 inline-block">
              <div className="bwp-widget-banner layout-1">
                <div className="bg-banner">
                  <div className="banner-wrapper banners relative">
                    <div className="bwp-image">
                      <a href="#">
                        <img
                          width={460}
                          height={263}
                          src="/assets/uploads/img1-1.jpg.webp"
                          alt="Banner Image"
                          data-lazy-src="/assets/uploads/img1-1.jpg.webp"
                          data-ll-status="loaded"
                          className="entered lazyloaded"
                        />
                      </a>
                    </div>
                    <div className="banner-wrapper-infor">
                      <div className="info">
                        <div className="content">
                          <div className="bwp-image-subtitle">
                            UP TO 30% OFF
                          </div>
                          <h3 className="title-banner">
                            Table &amp;
                            <br /> SMARTPHONE
                          </h3>
                          <a className="button" href="#">
                            Shop now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pb-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
          <div className="content-heading">
            <div className="title-block">
              <h2>Products</h2>
            </div>
            <div className="list-link">
              <ul>
                <li className="active">80% off </li>
                <li>
                  <a href="https://wpbingosite.com/wordpress/digic/product-category/computer-laptop/">
                    60% off
                  </a>
                </li>
                <li>
                  <a href="https://wpbingosite.com/wordpress/digic/shop/">
                    40% off
                  </a>
                </li>
                <li>
                  <a href="https://wpbingosite.com/wordpress/digic/product-category/digital-cameras/">
                    20% off
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {productData.map((item, i) => (
            <div className="w-1/5 inline-block">
              <div className="items">
                <div className="products-entry content-product7 clearfix product-wapper">
                  <div className="products-thumb">
                    <div className="product-lable">
                      <div className="vgwc-label vgwc-featured hot">Hot</div>
                      <div className="onsale" />
                    </div>
                    <div className="product-thumb-hover">
                      <a
                        href="https://wpbingosite.com/wordpress/digic/shop/smartphone-x-seri-02/"
                        className="woocommerce-LoopProduct-link"
                        tabIndex={0}
                      >
                        <img
                          width={480}
                          height={480}
                          src="/assets/uploads/Smartphone-X-seri-02-480x480.jpg.webp"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image entered lazyloaded"
                          data-lazy-src="/assets/uploads/Smartphone-X-seri-02-480x480.jpg.webp"
                          data-ll-status="loaded"
                        />
                        <img
                          width={480}
                          height={480}
                          src="/assets/uploads/Smartphone-X-seri-024-480x480.jpg.webp"
                          className="hover-image back entered lazyloaded"
                          data-lazy-src="/assets/uploads/Smartphone-X-seri-024-480x480.jpg.webp"
                          data-ll-status="loaded"
                        />
                      </a>
                    </div>{" "}
                    <div className="product-button">
                      <div data-title="View products">
                        <a
                          rel="nofollow"
                          href="https://wpbingosite.com/wordpress/digic/shop/smartphone-x-seri-02/"
                          data-quantity={1}
                          data-product_id={3989}
                          data-product_sku="VN00189"
                          className="button product_type_grouped read_more"
                          tabIndex={0}
                        >
                          View products
                        </a>
                      </div>
                      <div
                        className="woosw-wishlist"
                        data-title="Wishlist"
                      >
                        <button
                          className="woosw-btn woosw-btn-3989"
                          data-id={3989}
                          tabIndex={0}
                        >
                          Add to wishlist
                        </button>
                      </div>
                      <div
                        className="woosc-compare"
                        data-title="Compare"
                      >
                        <button
                          className="woosc-btn woosc-btn-3989"
                          data-id={3989}
                          tabIndex={0}
                        >
                          Compare
                        </button>
                      </div>
                      <span className="product-quickview">
                        <a
                          href="#"
                          data-title="Quick View"
                          data-product_id={3989}
                          className="quickview quickview-button quickview-3989"
                          tabIndex={0}
                        >
                          Quick View <i className="icon-visibility" />
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="products-content">
                    <div className="contents">
                      <div className="cat-products">
                        <a
                          href="https://wpbingosite.com/wordpress/digic/product-category/mobile-tablet/"
                          tabIndex={0}
                        >
                          Mobile &amp; Tablet
                        </a>
                      </div>
                      <h3 className="product-title">
                        <a
                          href="https://wpbingosite.com/wordpress/digic/shop/smartphone-x-seri-02/"
                          tabIndex={0}
                        >
                          Smartphone X seri 02
                        </a>
                      </h3>
                      <span className="price">
                        <span className="woocommerce-Price-amount amount">
                          <bdi>
                            <span className="woocommerce-Price-currencySymbol">
                              $
                            </span>
                            80.00
                          </bdi>
                        </span>
                        <span className="woocommerce-Price-amount amount">
                          <bdi>
                            <span className="woocommerce-Price-currencySymbol">
                              $
                            </span>
                            190.00
                          </bdi>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pt-6">
        <div
          style={{
            backgroundImage:
              "url(/assets/uploads/img6-9.jpg)",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="relative"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 flex py-8">
            {bannerData2.map((item, i) => (
              <div className="w-1/3 flex">
                <div className="p-2 w-full">
                  <div className="elementor-icon-box-wrapper flex items-center">
                    <div className="elementor-icon-box-icon inline-flex mr-3">
                      <span className="elementor-icon elementor-animation- text-lg fill-red-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Capa_1"
                          height={40}
                          viewBox="0 0 512 512"
                          width={40}
                        >
                          <g>
                            <path d="m512 234.836-99.35-127.169h-56.316v-42.667h-356.334v328.667h60.409c4.666 30.165 30.806 53.333 62.258 53.333s57.592-23.168 62.258-53.333h142.151c4.665 30.165 30.806 53.333 62.257 53.333s57.592-23.168 62.258-53.333h60.409zm-89.667 149.164c0 18.196-14.804 33-33 33s-33-14.804-33-33 14.804-33 33-33 33 14.804 33 33zm-266.666 0c0 18.196-14.804 33-33 33s-33-14.804-33-33 14.804-33 33-33 33 14.804 33 33zm-33-63c-27.624 0-51.135 17.879-59.614 42.667h-33.053v-98h205.314l29.905-104.667h-235.219v-66h296.333v268.667h-144.052c-8.479-24.788-31.99-42.667-59.614-42.667zm-92.666-85.333v-44.667h195.447l-12.762 44.667zm359.332 85.333c-12.093 0-23.396 3.432-33 9.363v-75.363h125.667v108.667h-33.053c-8.478-24.788-31.99-42.667-59.614-42.667zm-33-96v-87.333h41.684l68.23 87.333z" />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="elementor-icon-box-content text-white">
                      <h3 className="elementor-icon-box-title font-bold">
                        <span>FREE DELIVERY </span>
                      </h3>
                      <p className="elementor-icon-box-description">
                        When ordering from $500.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
