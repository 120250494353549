import React from "react";
// import {
//     AppBar,
//     Paper,
//     Container,
//     Box,
//     CssBaseline,
//     Toolbar,
//     Typography
// } from "@mui/material";
import LogoutButton from "./LogoutButton/LogoutButton";

function MainLayout({children, title}) {
    return (
        <React.Fragment>
            <LogoutButton/>

            {children}
        </React.Fragment>
    )
}

export default MainLayout
